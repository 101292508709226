import React from 'react'
import '../right/right.css'
import Mainright from '../main-right/MainRight'


function Right() {
  return (
    <>
      <Mainright 
      Title1="Categories"
      Img1={require('../image/hulk wj.jpg')} 
      Heading1="Hulk Power"
      Regularprice1= "₹999"
      Cutprice1= "₹1200"
      Img2={require('../image/iron man wj.jpg')} 
      Heading2="Iron Man"
      Regularprice2= "₹1350"
      Cutprice2= "₹1600"
      Img3={require('../image/man fuel cream 1.jpg')} 
      Heading3="Man Fule"
      Regularprice3= "₹1499"
      Cutprice3= "₹1700"
      button="All New Product"
      
      />  

      <div className="ayurvadic-img">
         <img src={require('../image/right-banner-1.jpg')} alt="" />
      </div>
    </>
  )
}

export default Right