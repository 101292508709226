import React from 'react'
import Logo from '../image/logo lupicad.png'
import '../footer/footer.css'

function Mainfooter() {
  return (
    <>
      <section className='mainfooter'>
        <div className="cont mmc">
          <ul id='sec1'>
            <li><img src={Logo} alt="footer logo" /></li>
            <li><p>TemplateMela offers website templates, wordpress themes, professional joomla templates offers website templates.</p></li>
          </ul>
          <ul id='sec2'>
            <li><h2>Reach Out to US</h2></li>
            <li><p>Lupicad.com
              LUPICAD 2519, Shradhanand Marg, Delhi-110006
              Tel: +91-97183 88999​ ( 10am-6pm )
              Email: care@lupicad.com</p></li>
          </ul>
          <ul id='sec3'>
            <li><h2>Quick Links</h2></li>
            <li>Home</li>
            <li>About</li>
            <li>Product</li>
            <li>Blog</li>
            <li>Contect</li>
          </ul>
          <ul id='sec4'><li><h2>Contect</h2></li>
          <li>
            <span>545453544</span>
            <span>wasim@gmail.com</span>
          </li>
          
          </ul>
        </div>
      </section>

    </>
  )
}

export default Mainfooter;