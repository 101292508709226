import ReactDOM from 'react-dom/client';
import Home from './pages/home/Home';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import About from './pages/home/About';
import Product from './pages/home/Product';
import Blog from './pages/home/Blog';
import Contect from './pages/home/Contect';
import Header from './components/header/Header';
import General from './components/cotegories/General';
import Male from './components/cotegories/Male';
import Female from './components/cotegories/Female';
import Sexual from './components/cotegories/Sexual';
import Jointpain from './components/cotegories/Jointpain';
import Liver from './components/cotegories/Liver';
import Brain from './components/cotegories/Brain';
import Detail from './components/detail/Detail';






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


 <BrowserRouter>
 <Header/>
 
     <Routes>
    
        <Route path='/' element={<Home/>}/>
        <Route path='/home' element={<Home/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='/product' element={<Product/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/contect' element={<Contect/>}/>
        <Route path='/general' element={<General/>}/>
        <Route path='/female' element={<Female/>}/>
        <Route path='/sexual' element={<Sexual/>}/>
        <Route path='/liver' element={<Liver/>}/>
        <Route path='/jointpain' element={<Jointpain/>}/>
        <Route path='/male' element={<Male/>}/>
        <Route path='/brain' element={<Brain/>}/>
        <Route path='/product/:productid' element={<Detail/>}/>

     </Routes>
 </BrowserRouter>
  

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
