import React, { useContext } from 'react'
import '../left/left.css'
import Mainright from '../main-right/MainRight'
import { Link } from "react-router-dom"
import { Hide } from '../navbar/Nav'




function Left() {
  const mainhide = useContext(Hide)
  console.log(mainhide);
 const hd=()=>{
   mainhide.setcls(false)
 }

  return (
    <>
    
        <div className="main-left">
            <div className="main-hd">
            <div className="hd-cotegrise">
                <h2>Categories</h2>
            </div>
            </div>
            <div className="ct-list">
                <ul>
                    <li><Link to="/general" onClick={hd}>General</Link></li>
                    <li><Link to="/male" onClick={hd}>Male</Link></li>
                    <li><Link to="/female" onClick={hd}>Female</Link></li>
                    <li><Link to="/sexual" onClick={hd}>Sexual</Link></li>
                    <li><Link to="/liver" onClick={hd}>Liver</Link></li>
                    <li><Link to="/jointpain" onClick={hd}>Joint Pain</Link></li>
                    <li><Link to="/brain" onClick={hd}>Brain</Link></li>
                    
                </ul>
            </div>
        </div>

        <div className="in-image">
            <img src={require('../image/left-banner-1.jpg')} alt=""/>
        </div>
<section className='myright'>
      <Mainright
        Title1="Best Sellers"
      Img1={require('../image/gond siyah r1.jpg')} 
      Heading1="Gond Siyah"
      Regularprice1= "1699"
      Cutprice1= ""
      Img2={require('../image/peni king.jpg')} 
      Heading2="Pani King"
      Regularprice2= "₹1350"
      Cutprice2= ""
      Img3={require('../image/shilajit 01.jpg')} 
      Heading3="Shilajit"
      Regularprice3= "₹899"
      Cutprice3= ""
      button="All New Product"
      />
      </section>
    </>
    
    
  )
}

export default Left