import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import products from '../product-api/Allproduct'
import Left from '../left/Left'
import Massege from '../Middle-section/Massege'
import Mainfooter from '../footer/Mainfooter'
import '../detail/detail.css'
import { Link } from 'react-router-dom'
import Slyder from '../product-slyder/Slyder'


function Detail() {
  const [val, setcal] = useState(1)
  let { productid } = useParams()
  const clkdata = products.find((p) => p.id == productid)
  const [back, setback] = useState(clkdata.backimage)
  const [front, setfront] = useState(clkdata.frontimage)

  const dec = () => {

    if (val > 1) {
      setcal(pr => pr - 1)
    }
  }
  const inc = () => {
    setcal(pr => pr + 1)
  }
  const ch = () => {
    if (front == clkdata.frontimage) {
      setfront(clkdata.backimage)

    } 
  }
  const fr = () => {
    if (front == clkdata.backimage) {
      setfront(clkdata.frontimage)
    }
  }

  return (
    <>
      <section>

        <div className="cont my-ct">
          <div className="left">
            <Left />
          </div>
          <div className="center newcenter">
            <div className="ct">
              <div className="inner-boxes">
                <div className="prodect-inner">
                  <span><Link to="/home">Home</Link></span>
                  <span>/</span>
                  <span>{clkdata.name}</span>
                  <span>/</span>

                </div>
                <div className="prodect-inner">
                  <div className="primge">
                    <img src={front} alt={clkdata.name} />
                  </div>
                </div>
                <div className="prodect-inner">
                  <div className="main-back">
                    <div className="backimg">  <img src={clkdata.frontimage} alt={clkdata.name} onClick={fr} /></div>
                    <div className="backimg">  <img src={clkdata.backimage} alt={clkdata.name} onClick={ch} /></div>
                    <div className="backimg">  <img src={clkdata.frontimage} alt={clkdata.name} /></div>
                  </div>
                </div>
              </div>
              <div className="inner-boxes2">
                <h1>{clkdata.name}</h1>
                <span>{clkdata.regularprice}</span>
                <div className="reting">
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </div>
                <div className="benefit">
                  <ul>
                    <li>{clkdata.li1}</li>
                    <li>{clkdata.li2}</li>
                    <li>{clkdata.li3}</li>
                    <li>{clkdata.li4}</li>
                    <li>{clkdata.li5}</li>
                    <li>{clkdata.li6}</li>
                  </ul>
                </div>
                <div className="qantiti">
                  <div className="inner-qantiti">
                    <span>Quantity</span>
                  </div>
                  <div className="inner-qantiti">
                    <div className="decrise" onClick={dec}><i class="fa-solid fa-minus"></i></div>
                    <div className="qwantiti-num">
                      <div className="qwantitival">{val}</div>
                    </div>
                    <div className="incrice" onClick={inc}><i class="fa-solid fa-plus"></i></div>
                  </div>

                </div>
                <div className="addtocart">
                  <a href="/"><i class="fa-solid fa-cart-shopping"></i> Add To Cart</a>
                </div>

              </div>
            </div>
            <section>
            <div className="main-dis">
              <h3>Description</h3>
              <div className="dis-box">
              <div className="benefit">
                  <ul>
                    <li>{clkdata.li1}</li>
                    <li>{clkdata.li2}</li>
                    <li>{clkdata.li3}</li>
                    <li>{clkdata.li4}</li>
                    <li>{clkdata.li5}</li>
                    <li>{clkdata.li6}</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          
      

          </div>
         
        </div>
      
        <div className='mycontainer'>
          <Massege />
        </div>
      </section>
      <Mainfooter />
    </>
  )
}

export default Detail