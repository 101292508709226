import React from 'react'
import Header from '../../components/header/Header';
import MiddleSec from '../../components/Middle-section/MiddleSec';
import Mainfooter from '../../components/footer/Mainfooter';
function Home() {
  return (
 <>
    <MiddleSec/>
    <Mainfooter/>
 </>
  )
}

export default Home;