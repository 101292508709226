import React from 'react'

function Massege() {
  return (
    <>
        <div className="inner-comp">
         <div className="ms">
         <i class="fa-regular fa-envelope"></i>
         <p>Newsletter</p>

        <div className="submitms">
            <input type="email" placeholder='Your email address' />
            <input  className="sub" name="submitNewsletter" type="submit" value="Subscribe"/>
        </div>
         </div>
        </div>
        <div className="inner-comp2">
        <i class="fa-brands fa-facebook-f"></i>
        <i class="fa-brands fa-instagram"></i>
        <i class="fa-brands fa-youtube"></i>
        <i class="fa-brands fa-twitter"></i>
        </div>
    </>
  )
}

export default Massege