import React, { createContext, useState } from 'react';
import '../product-card/product-card.css';
import { Link } from 'react-router-dom';
import { create } from '@mui/material/styles/createTransitions';

const Setstore = createContext()
function ProductCard(props) {
  const [prod, setprod] = useState(props.products);
  const [cart,setcart] = useState(0)

  const adcrt = ()=>{
    setcart(cart +1)
  
  }


console.log(cart);
  return (
    <Setstore.Provider value={cart}>
    <section>
      <div className="main-card">
        <Link to={`/product/${props.id}`}>
          <div className="card-img">
            <img src={props.frontimage} alt="Product" />
            <img src={props.backimage} id="hover-img" alt="Product" />
            <div className="spac-icon">
              <i className="fa-regular fa-eye"></i>
              <i className="fa-regular fa-heart"></i>
              <i className="fa-solid fa-arrows-rotate"></i>
            </div>
          </div>
          </Link>
          <div className="inner-content">
            <div className="reting">
            
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
         
            </div>
         
            <p>{props.name}</p>
  
            <div className="main-price">
              <span className="regular-price">{props.regularprice}</span>
              <span className="cut-price">{props.cutprice}</span>
              <div className="addtocart-btn">
           
                <a href="#" onClick={adcrt}>Add To Cart</a>
             
              </div>
            </div>
          
          </div>
      
      </div>
    </section>
    </Setstore.Provider>
  );
}

export default ProductCard;
export {Setstore}