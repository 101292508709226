import React from 'react'
import '../topbar/topbar.css' 
import Nav from '../navbar/Nav'

function Topbar(){
  return (
    <header className='main'>
        <div className="cont">
           <div className="inner-box">
            <img src={require('../image/logo lupicad.png')} alt='Logo'/>
           </div>
           <div className="inner-box2">
             <div className="inner-input">
              <input type="text" placeholder='Search products' />
             
             <div className="search">
             <i class="fa-solid fa-magnifying-glass"></i>
             </div>
               
             </div>
           </div>
        </div>
        <Nav/>
    </header>
  )
}

export default Topbar