import React, { createContext, useContext, useEffect, useState } from 'react'
import '../navbar/nav.css'
import { Link } from "react-router-dom"
import Left from '../left/Left'
import { Setstore } from '../product-card/ProductCard'
const Hide = createContext();
function Nav() {
  const [cls, setcls] = useState(false)
  const[smenu, setmenu]=useState(false)
  const cart = useContext(Setstore)
 
  console.log(cart);

  const clk = ()=> {
    setcls(!cls)
    if(cls ==false){
      setmenu(false)
    }
    
    else{
      setmenu(false)
    }
   
  }

  const mnclick = ()=>{
    setmenu(!smenu)
    if(smenu == false){
      setcls(false)
    }else{
      setcls(false)
    }
   
  }
const bdcl = ()=>{
  
}
useEffect(()=>{
  document.body.addEventListener('click', bdcl)

})

const hdd =()=>{
  setmenu(false)
}
return (
  <Hide.Provider value={{ cls , setcls}}>
    <div className="cont pr-ct">
      <div id="respon" class="inner-nav" className={smenu ? "up" : "down"}>
        <ul><li className={smenu ? "h" : "cuth"}><Link to="home"  onClick={hdd}>Home</Link></li>
          <li className={smenu ? "h" : "cuth"}><Link to="/about" onClick={hdd}>About</Link></li>
          <li className={smenu ? "h" : "cuth"}><Link to="/product" onClick={hdd}>Products</Link></li>
          <li className={smenu ? "h" : "cuth"}><Link to="/blog" onClick={hdd}>Blog</Link></li>
          <li className={smenu ? "h" : "cuth"}><Link to="/contect" onClick={hdd}>Contact</Link></li>
        </ul>      
      </div>
      <div id="menu" onClick={clk}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      <div className={cls ? "on" : "of"}>
      <div className='cut'>
      <span className={cls ? "" : "of"} onClick={clk}>X</span>
      </div>
      <Left/>
      </div>
      <div className="inner-nav2">
      <div id='main-menu' onClick={mnclick}>
      <i class="fa-solid fa-ellipsis-vertical"></i>
      </div>
        <i class="fa-sharp fa-solid fa-cart-shopping"></i>
        <span id='dn'>Cart</span>
        <span>({cart})</span>
        <i class="fa-solid fa-chevron-down"></i>
      </div>
    </div>
    </Hide.Provider>
  )
}
export{Hide}
export default Nav;