import React, { useState } from 'react'
import Left from '../../components/left/Left'
import Right from '../../components/right/Right'
import Mainfooter from '../../components/footer/Mainfooter'
import '../cotegories/cotegorise.css'
import products from '../product-api/Allproduct'
import ProductCard from '../product-card/ProductCard'


function Jointpain() {
    const [general, setgeneral]=useState(products)
  return (
    <>
     <div className="cont my-ct">
      <div className="left">
        <Left/>
      </div>
      <div className="center">
        <div className="general-box">
        {general
              .filter((ct) => ct.category2 === 'jointpain')
              .map((generalVal, i) => (
                <ProductCard
                  key={i}
                  id={generalVal.id}
                  name={generalVal.name}
                  frontimage={generalVal.frontimage}
                  backimage={generalVal.backimage}
                  regularprice={generalVal.regularprice}
                  cutprice={generalVal.cutprice}
                />
              ))}
        </div>
      </div>
      <div className="right">
        <Right/>
      </div>
    </div>
    <Mainfooter/>
    </>
  )
}

export default Jointpain