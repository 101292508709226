import React, { useState } from 'react'
import '../center/center.css'
import Slyder from '../product-slyder/Slyder'
import freeimg from '../image/or.png'
import products from '../product-api/Allproduct';

function Center() {
  const [dta, setdta] = useState(products)
  return (
    <>
      <section className='main-sl'>

        <div id="demo" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000" data-bs-touch="true">
          <div class="carousel-inner">
            <div class="carousel-item active my">
              <img src={require('../image/sample-1.jpg')} alt="slide1" />
              <div className="main-inner-box">
                <div className="innertext">
                  <h3>Spacial Discount On</h3>
                  <h2>Devison Ayurvedic Medicines</h2>
                  <a href="/">Shop Now</a>
                </div>
              </div>
            </div>
            <div class="carousel-item my">
              <img src={require('../image/sample-2.jpg')} alt="slide2" />
              <div className="main-inner-box">
                <div className="innertext">
                  <h3>Spacial Discount On</h3>
                  <h2>Devison Ayurvedic Medicines</h2>
                  <a href="/">Shop Now</a>
                </div>
              </div>
            </div>

          </div>

          <button class="carousel-control-prev my-bt" type="button" data-bs-target="#demo" data-bs-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </button>
          <button class="carousel-control-next my-bt" type="button" data-bs-target="#demo" data-bs-slide="next">
            <span class="carousel-control-next-icon"></span>
          </button>
        </div>
      </section>

      {/* start product section */}

      <div className="hd-cotegrise mi-text">
        <div className="inner-heading">
          <h2>Featured Products</h2>
        </div>

      </div>

      <section className='pord'>
        <Slyder />
      </section>

      <div className="freedelevery">
        <img src={freeimg} alt="free delevery" />
      </div>

      <div className="hd-cotegrise mi-text">
        <div className="inner-heading">
          <h2>New Products</h2>
        </div>

      </div>

      <section className='pord'>
        {dta.map((val2, k) => {
          let x = dta.filter((user) => user.category === "male")
          console.log(...x)
          if (k < 1) {
            return (

              <Slyder key={k} id={x.id} name={x.name} frontimage={x.frontimage} backimage={x.backimage} regularprice={x.regularprice} cutprice={x.cutprice} />


            )
          }


        })}
      </section>


    </>
  )
}

export default Center