import React, { useState } from 'react'
import Left from '../../components/left/Left'
import Right from '../../components/right/Right'
import Mainfooter from '../../components/footer/Mainfooter'
import ProductCard from '../../components/product-card/ProductCard'
import products from '../../components/product-api/Allproduct'
import '/Users/WIM_ADMIN/project1/src/components/center/center.css'

let allpro = (products)
function Product() {
 
  return (
    <>
    <div className="cont my-ct">
      <div className="left">
        <Left/>
      </div>
      <div className="center">
       <div className="flbx">
       {
          allpro.map((e , i)=>{
            return(
              <ProductCard key={i} id={e.id} name={e.name} frontimage={e.frontimage} backimage={e.backimage} regularprice={e.regularprice} cutprice={e.cutprice}/>
            )
          })
        }
       </div>
      </div>
      <div className="right">
        <Right/>
      </div>
    </div>
    <Mainfooter/>
   </>
  )
}

export default Product