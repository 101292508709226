import React from 'react'
import '../main-right/main-right.css'
import { Link } from 'react-router-dom';
function Mainright(props) {

  return (
    <>
      <div className="main-left">
            <div className="main-hd">
            <div className="hd-cotegrise">
                <h2>{props.Title1}</h2>
            </div>
            </div>
            <div className="ct-list">
                <div className="recent-pro">
                  <div className="pr-img">
                    <img src={props.Img1} alt="Hulk power" />
                  </div>
                  <div className="price">
                      <h4>{props.Heading1}</h4>
                      <div className="main-price">
                      <span className='regular-pr'>{props.Regularprice1}</span>
                      <span className='cut-price'>{props.Cutprice1}</span>
                      </div>
                  </div>
                </div>
                <div className="recent-pro">
                  <div className="pr-img">
                    <img src={props.Img2} alt="Iron man" />
                  </div>
                  <div className="price">
                      <h4>{props.Heading2}</h4>
                      <div className="main-price">
                      <span className='regular-pr'>{props.Regularprice2}</span>
                      <span className='cut-price'>{props.Cutprice2}</span>
                      </div>
                  </div>
                </div>
                <div className="recent-pro">
                  <div className="pr-img">
                    <img src={props.Img3} alt="Man Fule" />
                  </div>
                  <div className="price">
                      <h4>{props.Heading3}</h4>
                      <div className="main-price">
                      <span className='regular-pr'>{props.Regularprice3}</span>
                      <span className='cut-price'>{props.Cutprice3}</span>
                      </div>
                  </div>
                </div>
                <div className="bt">
                  <Link to="/product">{props.button}</Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default Mainright;