import React from 'react'
import Left from '../../components/left/Left'
import Right from '../../components/right/Right'
import Mainfooter from '../../components/footer/Mainfooter'

function Blog() {
  return (
    <>
    <div className="cont my-ct">
      <div className="left">
        <Left/>
      </div>
      <div className="center">
        <h1>This is a Blog Page</h1>
      </div>
      <div className="right">
        <Right/>
      </div>
    </div>
    <Mainfooter/>
   </>
  )
}

export default Blog