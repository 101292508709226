import React, { useState } from 'react'
import '../product-slyder/slyder.css'
import ProductCard from '../product-card/ProductCard'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import products from '../product-api/Allproduct';



function Slyder() {
    const [crdata, setcrdata] = useState(products)
    
    const options = {
        items: 3,
        loop: true,
        autoplay: false,
        autoplayTimeout: 4000,
        nav: true,
        dots: false,
        margin: 0,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    return (
        <>
           <OwlCarousel className="owl-theme" {...options}>
                <div class="item">
                    
                {crdata.map((item, i)=>{
                    let randomnum= Math.floor(
                        Math.random()*crdata.length
                    )
                         if(i<3){
                            return(
                          
                          <ProductCard key={i} id={crdata[randomnum].id} name={crdata[randomnum].name} frontimage={crdata[randomnum].frontimage} backimage={crdata[randomnum].backimage} regularprice={crdata[randomnum].regularprice} cutprice={crdata[randomnum].cutprice}/> 
                         
                      )
                         }
                
                    
                      })}
                </div>
                <div class="item">
                {crdata.map((item, i)=>{
                    let randomnum=Math.floor(
                        Math.random()*crdata.length
                    )
                         if(i<3){
                            return(
                          
                          <ProductCard key={i} id={crdata[randomnum].id} name={crdata[randomnum].name} frontimage={crdata[randomnum].frontimage} backimage={crdata[randomnum].backimage} regularprice={crdata[randomnum].regularprice} cutprice={crdata[randomnum].cutprice}/> 
                         
                      )
                         }
                
                    
                      })}
                </div>
                <div class="item">
                {crdata.map((item, i)=>{
                    let randomnum=Math.floor(
                        Math.random()*crdata.length
                    )
                         if(i<3){
                            return(
                          
                          <ProductCard key={i} id={crdata[randomnum].id} name={crdata[randomnum].name} frontimage={crdata[randomnum].frontimage} backimage={crdata[randomnum].backimage} regularprice={crdata[randomnum].regularprice} cutprice={crdata[randomnum].cutprice}/> 
                          
                      )
                         }
                                
                      })}
                </div>
        </OwlCarousel>
        </>
    )
}

export default Slyder;