import React from 'react'
import '../Middle-section/middle-sec.css'
import Left from '../left/Left';
import Center from '../center/Center';
import Right from '../right/Right';
import Massege from './Massege';

function MiddleSec() {
  return (
   <>
    <div className="cont my-ct">
      <div className="left">
        <Left/>
      </div>
      <div className="center">
        <Center/>
      </div>
      <div className="right">
        <Right/>
      </div>

    </div>
    <div className='mycontainer'>
      <Massege/>
    </div>
   </>
  )
}

export default MiddleSec;