import fronthulk from '../image/hulk wj.jpg';
import backhulk from '../image/hulk 2.jpg';
import frontironman from '../image/iron man wj.jpg';
import backironman from '../image/iron man 2.jpg';
import frontmanfule from '../image/man fule wj.jpg';
import backmanfule from '../image/man fuel listing 2 (1).jpg';
import frontgondsiyah from '../image/gond siyah r1.jpg';
import backgondsiyah from '../image/hulk 2.jpg';
import fronttestopro from '../image/Testo 1.jpg';
import backtestopro from '../image/test 2.jpg';
import frontmansupport from '../image/men support.jpg';
import backmansupport from '../image/men support 2 (1).jpg';
import frontantiadd from '../image/anti addiction.jpg';
import backantiadd from '../image/anti addiction 2.jpg';
import frontasvgandha from '../image/ashwagandha.jpg';
import backasvgandha from '../image/ashwangandha 2.jpg';
import frontshilajit from '../image/shilajit liquid.jpg';
import backshilajit from '../image/shilajit 02.jpg';
import frontbrainfit from '../image/brain fit.jpg';
import backbrainfit from '../image/brain fit 1.jpg';
import frontbreathfree from '../image/breathfree.jpg';
import backbreathfree from '../image/breath free 1.jpg';
import frontdiabolac from '../image/diabo lac.jpg';
import backdiabolac from '../image/diabo lac 1.jpg';
import frontfatburner from '../image/fat burner.jpg';
import backfatburner from '../image/fat 1.jpg';
import frontfenugreek from '../image/fenugreek.jpg';
import backfenugreek from '../image/fenugreek 1.jpg';
import frontflaxseed from '../image/flaxseed softgel capsule.jpg';
import backflaxseed from '../image/flaxseed softgel 1.jpg';
import frontgarcinia from '../image/garcinia cambegia.jpg';
import backgarcinia from '../image/garcinia 1.jpg';
import frontgarlic from '../image/garlic capsule.jpg';
import backgarlic from '../image/garlic 1.jpg';
import frontgoksura from '../image/gokshura.jpg';
import backgoksura from '../image/gokshura.jpg';
import frontguduchi from '../image/guduchi.jpg';
import backguduchi from '../image/guduchi 1.jpg';
import fronthaircare from '../image/hair care.jpg';
import backhaircare from '../image/hair care 1.jpg';
import frontheartcare from '../image/heat care.jpg';
import backheartcare from '../image/heart care 1.jpg';
import frontjaundice from '../image/jaundice.jpg';
import backjaundice from '../image/jaundice 1.jpg';
import frontjointcare from '../image/joint care.jpg';
import backjointcare from '../image/joint care 1.jpg';
import frontkalonji from '../image/kalonji softgel capsule.jpg';
import backkalonji from '../image/kalonji 1.jpg';
import frontkarela from '../image/karela.jpg';
import backkarela from '../image/karela 1.jpg';
import frontkorean from '../image/korean ginseng.jpg';
import backkorean from '../image/korean 1.jpg';
import frontmoringa from '../image/moringa.jpg';
import backmoringa from '../image/moringa 1.jpg';
import frontmigrain from '../image/migrain go.jpg';
import backmigrain from '../image/migrain go 1.jpg';
import frontmultivitamins from '../image/multi vitamins.jpg';
import backmultivatamins from '../image/multi vitamins 1.jpg';
import frontmusli from '../image/musli.jpg';
import backmusli from '../image/musli.jpg';
import frontoliveoil from '../image/olive oil.jpg';
import backoliveoil from '../image/olive oil 1.jpg';
import frontomega3 from '../image/omega 3 fish oil.jpg';
import backomega3 from '../image/omega 1.jpg';
import frontoxygain from '../image/oxygain q10.jpg';
import backoxygain from '../image/oxygain 1.jpg';
import frontpilescare from '../image/piles care.jpg';
import backpilescare from '../image/pile 1.jpg';
import frontstomach from '../image/stomach care.jpg';
import backstomuch from '../image/stomach 1.jpg';
import frontstonery from '../image/stonery caps.jpg';
import backstonery from '../image/stonery 1.jpg';
import frontmanfuelcream from '../image/man fuel cream 1.jpg';
import backmanfuelcream from '../image/man fuel cream 2.jpg';
import frontspermultra from '../image/sperm ultra.jpg';
import backspermultra from '../image/sperm 1.jpg';
import fronttargetpower from '../image/Target power 1.jpg';
import backtargetpower from '../image/target Power listing 2.jpg';
import frontbcutecaps from '../image/b cute caps  (1).jpg';
import backbcutecaps from '../image/b cute caps 2 (2).jpg';
import frontbcutecream from '../image/b cute cream 1.jpg';
import backbcutecream from '../image/b cure cream 2.jpg';
import frontglutathonia from '../image/glutathione.jpg';
import backglutathonia from '../image/glutathione 1.jpg';
import frontgyno from '../image/gyno medic.jpg';
import backgyno from '../image/gyno medic caps 1.jpg';
import frontmissvv from '../image/miss vv.jpg';
import backmissvv from '../image/miss vv.jpg'; 
import frontpocos from '../image/pocos care.jpg';
import backpocos from '../image/pocos 1.jpg';
import frontpeniking from '../image/peni king.jpg';
import backpeniking from '../image/peni king 2.jpg';
import frontbb from '../image/big b plus 1.jpg';
import backbb from '../image/peni king 2.jpg';








const products = [
    {
        id:1,
        category: "male",
        category2: "sexual",
        name: "Hulk Power",
        regularprice: 899,
        cutprice: 1199,
        frontimage: fronthulk,
        backimage: backhulk,
        li1: "Works by relaxing the blood vessels in your penis",
        li2: "It helps to improve muscle strength by promoting muscle growth.",
        li3: "Boost energy levels and stamina supports a normal and elevated mood",
        li4: "Increase blood to flow into the penis and produce an erection",
        li5: "Highly effective in providing vigor and vitality.",
    },
    {
        id:2,
        category: "male",
        category2: "sexual",
        name: "Iron Man",
        regularprice: 1350,
        cutprice: 1599,
        frontimage: frontironman,
        backimage: backironman,
        li1: "Stamina supports a normal and elevated mood",
        li2: "Useful in physical weakness",
        li3: "Beneficial in Boosting Imperatives, Sex Timing, and Stamina",
        li4: "Boost Vitality and Vigor",
        li5: "Helps to improve muscle strength by promoting muscle growth",
    },
    {
        id:3,
        category: "general",
        name: "Anti Adiction",
        regularprice: 1799,
        cutprice: 2099,
        frontimage: frontantiadd,
        backimage: backantiadd,
        li1: "Helps in alcohol dependence",
        li2: "Reduce the craving for alcohol",
        li3: "Nourishes the body affected parts",
        li4: "Helps to improve digestion",
        li5: "Made from natural herbs",
        li6: "No harmful effects",
    },
    {
        id:4,
        category: "general",
        name: "Ashwagandha",
        regularprice: "₹999",
        cutprice: "₹1299",
        frontimage: frontasvgandha,
        backimage: backasvgandha,
        li1: "Prevents anxiety and depression",
        li2: "Boost bone health",
        li3: "Improves underweight conditions",
        li4: "Good for heart health",
        li5: "Enhances libido",
        li6: "100% natural herbal",
    },
    {
        id:5,
        category: "general",
        category2: "sexual",
        name: "Shilajit",
        regularprice: "₹899",
        cutprice: "₹1099",
        frontimage: frontshilajit,
        backimage: backshilajit,
        li1: "Increases strength and stamina",
        li2: "Natural energy booster",
        li3: "Make you weakness free",
        li4: "Boost your performance and productivity",
        li5: "It improve your immunity and memory",
        li6: "100% herbal and pure",
    },
    {
        id:6,
        category: "general",
        category2: "brain",
        name: "Brain Fit",
        regularprice: "₹699",
        cutprice: "₹999",
        frontimage: frontbreathfree,
        backimage: backbreathfree,
        li1: "Beneficial Heart diseases",
        li2: "Harmful in stopping cancer cell growth",
        li3: "Certified by GMP and other major institutions",
        li4: "Improves skin and hair texture",
        li5: "100% herbal product",
        li6: "Zero side effect",
    },
    {
        id:7,
        category: "general",
        name: "Diabo Lic",
        regularprice: "₹899",
        cutprice: "₹1099",
        frontimage: frontdiabolac,
        backimage: backdiabolac,
        li1: "Improves sensitivity of insulin receptors",
        li2: "Support healthy insulin response",
        li3: "Regulates the amount of suger in the blood",
        li4: "Controls high blood pressure",
        li5: "Prevent the risk of Diabetes",
        li6: "Remove fat deposits from the liver",
    },
    {
        id:8,
        category: "general",
        name: "Fat Burner",
        regularprice: "₹1599",
        cutprice: "₹1799",
        frontimage: frontfatburner,
        backimage: backfatburner,
        li1: "Relievese excessive weight problem",
        li2: "Boost metabolism system",
        li3: "Boost body energy",
        li4: "Zero side effects",
        li5: "Advance fat burner.",
        li6: "100% pure and natural herbs",
    },
    {
        id:9,
        category: "general",
        name: "Fenugreek Capsule",
        regularprice: "₹799",
        cutprice: "₹999",
        frontimage: frontfenugreek,
        backimage: backfenugreek,
        li1: "Supplement for breastfeeding mother",
        li2: "Maintain liver and kidney health",
        li3: "Balances cholesterol levels",
        li4: "Blood suger controls",
        li5: "Testosterone level booster.",
        li6: "100% herbal product",
    },
    {
        id:10,
        category: "general",
        name: "Flaxseed Softgel Capsule",
        regularprice: "₹899",
        cutprice: "₹1199",
        frontimage: frontflaxseed,
        backimage: backflaxseed,
        li1: "Reduced cravings for harmful foods",
        li2: "Positive mood (happiness, feeling, energetic, etc.)",
        li3: "Increased concentration and energy",
        li4: "Improved bowel movement",
        li5: "Reduced joint pain",
        li6: "100% herbal and pure",
    },
    {
        id:11,
        category: "general",
        name: "Garcinia Cambogia",
        regularprice: "₹999",
        cutprice: "₹1299",
        frontimage: frontgarcinia,
        backimage: backgarcinia,
        li1: "Reduced cravings for harmful foods",
        li2: "Positive mood (happiness, feeling, energetic, etc.)",
        li3: "Increased concentration and energy",
        li4: "Improved bowel movement",
        li5: "Reduced joint pain",
        li6: "100% herbal and pure",
    },
    {
        id:12,
        category: "general",
        name: "Garlic",
        regularprice: "₹699",
        cutprice: "₹1099",
        frontimage: frontgarcinia,
        backimage: backgarlic,
        li1: "Herbal garlic extract capsule",
        li2: "Odorless garlic capsule for high blood pressure",
        li3: "Improve cholesterol level",
        li4: "Enhance fitness",
        li5: "Improves overall health",
        li6: "100%  herbal product",
    },
    {
        id:13,
        category: "general",
        name: "Gokshura",
        regularprice: "₹899",
        cutprice: "₹1099",
        frontimage: frontgoksura,
        backimage: backgoksura,
        li1: "It helps to manage anxiety",
        li2: "Gives strenght to our body",
        li3: "Reduce blood suger levels",
        li4: "Treat kidney stones",
        li5: "Beneficial in sexual health",
        li6: "100% natural herbal",
    },
    {
        id:14,
        category: "general",
        category2: "jointpain",
        name: "Gond Siyah",
        regularprice: "₹1799",
        cutprice: "₹1999",
        frontimage: frontgondsiyah,
        backimage: backgondsiyah,
        li1: "Gives strength to your bones",
        li2: "Maintain men’s reproductive health",
        li3: "It increases immunity aids in weight loss",
        li4: "It increases endurance",
        li5: "It helps to revitalize energy",
        li6: "Natural & herbal product",
    },
    {
        id:15,
        category: "general",
        name: "Guduchi",
        regularprice: "₹799",
        cutprice: "₹1099",
        frontimage: frontguduchi,
        backimage: backguduchi,
        li1: "Improve digestion power",
        li2: "Support function of liver and kidneys",
        li3: "Increase mind power",
        li4: "Helps in weight loss",
        li5: "Prevent cold and flu",
        li6: "Natural herbs  no side effects",
    },
    {
        id:16,
        category: "general",
        name: "Hair Care",
        regularprice: "₹1399",
        cutprice: "₹1599",
        frontimage: fronthaircare,
        backimage: backhaircare,
        li1: "Reducing hair loss and boost hair growth",
        li2: "Certified by GMP and other major institutions",
        li3: "Helpful for skin and nail",
        li4: "Zero side effect",
        li5: "Save you from baldness",
        li6: "Makes hair thick and strong",
    },
    {
        id:17,
        category: "general",
        name: "Heart Care",
        regularprice: "₹1499",
        cutprice: "₹1699",
        frontimage: frontheartcare,
        backimage: backheartcare,
        li1: "Ensures relief from heart stress",
        li2: "Helpful in control cholesterol level",
        li3: "Work as a blood purifier",
        li4: "Purifes lungs from dust",
        li5: "Certified by GMP",
        li6: "No side effect",
    },
    {
        id:18,
        category: "general",
        name: "Jaundice",
        regularprice: "₹280",
        cutprice: "₹280",
        frontimage: frontjaundice,
        backimage: backjaundice,
        li1: "Useful in (Jaundice) and loss of appetite",
        li2: "Also improves digestive system",
        li3: "Protect the liver against hepatotoxins",
        li4: "Promotes healthy growth and weight gain",
        li5: "Made from natural herbs",
        li6: "No side effect",
    },
    {
        id:19,
        category: "general",
        category2: "jointpain",
        name: "Joint Care",
        regularprice: "₹1299",
        cutprice: "₹1499",
        frontimage: frontjointcare,
        backimage: backjointcare,
        li1: "High efficacy in minimum dosage",
        li2: "Useful in joint pain",
        li3: "Quick absorption and fast action",
        li4: "Suitable for long term  usage",
        li5: "100% ayurvedic",
        li6: "No side effect",
    },
    {
        id:20,
        category: "general",
        name: "Kalonji Softgel",
        regularprice: "₹899",
        cutprice: "₹1099",
        frontimage: frontkalonji,
        backimage: backkalonji,
        li1: "Helps in weight loss",
        li2: "Regulate blood pressure",
        li3: "Best immunity booster",
        li4: "Very efficient to prevent diabetes",
        li5: "Packed with antioxidants",
        li6: "Lowers cholesterol",
    },
    {
        id:21,
        category: "general",
        name: "Karela",
        regularprice: "₹699",
        cutprice: "₹999",
        frontimage: frontkarela,
        backimage: backkarela,
        li1: "Maintains normal blood glucose metabolism",
        li2: "Reduces blood sugar",
        li3: "Helps to relate sugar levels",
        li4: "Helps to boost the immunity system",
        li5: "Aids  proper digestion",
        li6: "Boost liver enzymes",
    },
    {
        id:22,
        category: "general",
        name: "Korean Ginseng",
        regularprice: "₹899",
        cutprice: "₹1099",
        frontimage: frontkorean,
        backimage: backkorean,
        li1: "Beneficial in building and strengthening",
        li2: "Takes care of your skin",
        li3: "Lowers stress level",
        li4: "Boosts immunity system",
        li5: "Natural product",
        li6: "Zero side effect",
    },
    {
        id:23,
        category: "general",
        name: "Migrain Go",
        regularprice: "₹2699",
        cutprice: "₹2999",
        frontimage: frontmigrain,
        backimage: backmigrain,
        li1: "Migraine relief medicine",
        li2: "Migraine pain reliever",
        li3: "Best treatment for Migraine",
        li4: "100% ayurvedic product",
        li5: "Zero side effect",
        li6: "Certified by GMP",
    },
    {
        id:24,
        category: "general",
        name: "Moringa",
        regularprice: "₹999",
        cutprice: "₹1099",
        frontimage: frontmoringa,
        backimage: backmoringa,
        li1: "Regulates blood sugar levels",
        li2: "Reduces joint pain",
        li3: "Make your skin healthy",
        li4: "100% ayurvedic product",
        li5: "Zero side effect",
        li6: "GMP quality assured",
    },
    {
        id:25,
        category: "general",
        name: "Multi  Vitamins",
        regularprice: "₹899",
        cutprice: "₹1099",
        frontimage: frontmultivitamins,
        backimage: backmultivatamins,
        li1: "Provide necessary vitamins and nutritions to the body",
        li2: "Improves the body’s energy and stamina",
        li3: "Boosts immunity system",
        li4: "Gives you healthy skin and texture",
        li5: "100% herbal product",
        li6: "Zero side effect",
    },
    {
        id:26,
        category: "general",
        name: "Musli",
        regularprice: "₹2199",
        cutprice: "₹2499",
        frontimage: frontmusli,
        backimage: backmusli,
        li1: "Its good for heart health",
        li2: "Beneficial in boosting sexual performance",
        li3: "Useful in gaining weight and muscle mass",
        li4: "It helps to manage diabetes",
        li5: "100% herbal product",
        li6: "No side effects",
    },
    {
        id:27,
        category: "general",
        name: "Olive Oil",
        regularprice: "₹1299",
        cutprice: "₹1499",
        frontimage: frontoliveoil,
        backimage: backoliveoil,
        li1: "Beneficial in heart diseases",
        li2: "Maintains cholesterol level",
        li3: "Helpful in high blood pressure",
        li4: "100% herbal product",
        li5: "Improves overall health",
        li6: "No side effect",
    },
    {
        id:28,
        category: "general",
        name: "Omega-3 Fish Oil",
        regularprice: "₹750",
        cutprice: "₹1099",
        frontimage: frontomega3,
        backimage: backomega3,
        li1: "Beneficial in reducing inflammation",
        li2: "Helpful in mental health",
        li3: "Beneficial in heart health",
        li4: "Useful in weight loss",
        li5: "Natural product",
        li6: "Zero side effect",
    },
    {
        id:29,
        category: "general",
        name: "Oxygain Q10",
        regularprice: "₹1299",
        cutprice: "₹1499",
        frontimage: frontoxygain,
        backimage: backoxygain,
        li1: "Beneficial in heart diseases",
        li2: "Maintains cholesterol level",
        li3: "Helpful in high blood pressure",
        li4: "Improve overall health",
        li5: "100% ayurvedic product",
        li6: "No side effect",
    },
    {
        id:30,
        category: "general",
        name: "Piles Care",
        regularprice:  "₹1499",
        cutprice: "₹1899",
        frontimage: frontpilescare,
        backimage: backpilescare,
        li1: "Controls bleeding",
        li2: "Eases pain and swelling",
        li3: "Heals mucous membrane",
        li4: "Treat constipation",
        li5: "100% hurbal Product",
        li6: "Zero side effect",
    },
    {
        id:31,
        category: "general",
        name: "Stomach Care",
        regularprice: "₹1299",
        cutprice: "₹1499",
        frontimage: frontstomach,
        backimage: backstomuch,
        li1: "Best for digestive and immune health",
        li2: "Regulates blood sugar level",
        li3: "Reduces cholesterol levels ",
        li4: "Helps relieve constipation",
        li5: "No side effect",
        li6: "Certified with GMP",
    },
    {
        id:32,
        category: "general",
        name: "Stonery",
        regularprice: "₹1299",
        cutprice: "₹1599",
        frontimage: frontstonery,
        backimage: backstonery,
        li1: "Helps prevent kidney stone stone formation",
        li2: "It allows faster detoxification",
        li3: "Highly effective against urinary tract infections",
        li4: "Reduces urine burning sensation",
        li5: "Natural herbal and pure",
        li6: "No other side effects",
    },
    {
        id:33,
        category: "male",
        category2: "sexual",
        name: "Man Fuel",
        regularprice: "₹1499",
        cutprice: "₹1699",
        frontimage: frontmanfule,
        backimage: backmanfule,
        li1: "Boosts Immunity",
        li2: "Sex Stamina Tablet",
        li3: "Treats Low Libido",
        li4: "Promotes Penis muscle growth",
        li5: "Supports Stamina",
        li6: "Helps to Improve muscle strength",
    },
    {
        id:34,
        category: "male",
        category2: "sexual",
        name: "Man Fuel Cream",
        regularprice: "₹1499",
        cutprice: "₹1699",
        frontimage: frontmanfuelcream,
        backimage: backmanfuelcream,
        li1: "Reduce sexual Problems",
        li2: "Increase size and width",
        li3: "Boosts sexual performance",
        li4: "100% ayurvedic",
        li5: "Zero side effect",
        li6: "Certified by GMP",
    },
    {
        id:35,
        category: "male",
        category2: "sexual",
        name: "Man Support",
        regularprice: "₹2250",
        cutprice: "₹2599",
        frontimage: frontmansupport,
        backimage: backmansupport,
        li1: "Boosts stamina and endurance in man",
        li2: "Treat erectile dysfunction",
        li3: "Gives strength to sexual organs",
        li4: "Natural immunity booster",
        li5: "Start living a pleasurable life",
        li6: "100% Ayurvedic product",
    },
    {
        id:36,
        category: "male",
        category2: "sexual",
        name: "Peni King",
        regularprice: "₹1350",
        cutprice: "₹1599",
        frontimage: frontpeniking,
        backimage: backpeniking,
        li1: "Makes good erection & timing",
        li2: "Better confidence in bad",
        li3: "Improve sexual confidence",
        li4: "Increased size and thickness",
        li5: "Naturally treatment",
        li6: "No side effect",
    },
    {
        id:37,
        category: "male",
        category2: "sexual",
        name: "Sperm Ultra",
        regularprice: "₹4000",
        cutprice: "₹4999",
        frontimage: frontspermultra,
        backimage: backspermultra,
        li1: "Beneficial in increasing the sperm count",
        li2: "Also useful for the sexual dysfunction like ED and low libido",
        li3: "A boon for hopeless couples",
        li4: "certified by GMP and other major institutions",
        li5: "100% ayurvedic product",
        li6: "Zero side effect",
    },
    {
        id:38,
        category: "male",
        category2: "sexual",
        name: "Target Power",
        regularprice: "₹649",
        cutprice: "₹999",
        frontimage: fronttargetpower,
        backimage: backtargetpower,
        li1: "Controls stress by maintaining the stress",
        li2: "Improves Sexual drive",
        li3: "Increases stamina & time period of an Intercourse",
        li4: "Increases Male libido",
        li5: "Improves muscle strength by promoting muscle growth",
        li6: "Regains the lost energy and willpowe Boosts energy levels",
    },
    {
        id:39,
        category: "male",
        category2: "sexual",
        name: "Test-O-Pro",
        regularprice: "₹1249",
        cutprice: "₹1499",
        frontimage: fronttestopro,
        backimage: backtestopro,
        li1: "Harder & Long Lasting Penis Erections",
        li2: "Boosts Sexually Long Time",
        li3: "Enhances the activity of all body organs",
        li4: "Long-lasting stamina & improved sexual performance",
        li5: "Cure fatigue and improve overall energy levels",
        li6: "Beneficial in Increase Vitality",
    },
    {
        id:40,
        category: "female",
        category2: "sexual",
        name: "B-Cute",
        regularprice: "₹1299",
        cutprice: "₹1499",
        frontimage: frontbcutecaps,
        backimage: backbcutecaps,
        li1: "Harder & Long Lasting Penis Erections",
        li2: "Boosts Sexually Long Time",
        li3: "Enhances the activity of all body organs",
        li4: "Long-lasting stamina & improved sexual performance",
        li5: "Cure fatigue and improve overall energy levels",
        li6: "Beneficial in Increase Vitality",
    },
    {
        id:41,
        category: "female",
        name: "B-Cute Cream",
        category2: "sexual",
        regularprice: "₹1499",
        cutprice: "₹1799",
        frontimage: frontbcutecream,
        backimage: backbcutecream,
        li1: "Breast size reduction cream is 100%  organic",
        li2: "Burn extra fat accumulated around breast",
        li3: "Enhance attractiveness and appeal",
        li4: "Hide sagging and gives good shape",
        li5: "Natural cream to reduce breast size",
        li6: "100% natural  no side effects",
    },
    {
        id:42,
        category: "female",
        category2: "sexual",
        name: "Big B-Plus",
        regularprice: "₹1199",
        cutprice: "₹1399",
        frontimage: frontbb,
        backimage: backbb,
        li1: "Balances uneven breasts  and tones its shape",
        li2: "Improves shapes  it an attractive contour",
        li3: "Reshapes loose curves due to aging",
        li4: "Enlarge your breast to prefect",
        li5: "Natural and proven cream",
        li6: "100% ayurvedic, no side effect",
    },
    
    {
        id:43,
        category: "female",
        name: "Glutathione",
        regularprice: "₹1299",
        cutprice: "1599",
        frontimage: frontglutathonia,
        backimage: backglutathonia,
        li1: "Beneficial in premature skin ageing",
        li2: "Full of nutrients like vitamin c, vitamin E, & biotin etc",
        li3: "It can also helps in hair regrowth",
        li4: "Boosts immunity naturally",
        li5: "100% ayurvedic product",
        li6: "No side effect"
    },
    {
        id:44,
        category: "female",
        name: "Gyno Medic",
        regularprice: "1499",
        cutprice: "₹1699",
        frontimage: frontgyno,
        backimage: backgyno,
        li1: "Treat all gynecological disorders",
        li2: "Help support regular menstural cycle",
        li3: "Helpful in leucorrhea",
        li4: "Maintains mood swing",
        li5: "Helpful in stomach health",
        li6: "100% ayurvedic & no side effects"
    },
    {
        id:45,
        category: "female",
        category2: "sexual",
        name: "Miss VV",
        regularprice: "₹1699",
        cutprice: "₹1899",
        frontimage: frontmissvv,
        backimage: backmissvv,
        li1: "Restoring elasticity after pregnancy",
        li2: "Preventing dryness, itching & irritation",
        li3: "Intimate wellness especially",
        li4: "Prevent intimate infections",
        li5: "100% natural and safe all skin type",
        li6: "Made from natural herbs"
    },
    {
        id:46,
        category: "female",
        name: "Pcos Care",
        regularprice: "₹1399",
        cutprice: "₹1599",
        frontimage: frontpocos,
        backimage: backpocos,
        li1: "Regularises Periods",
        li2: "Prevents facial hair",
        li3: "Manages weight and bloating",
        li4: "Improves fertility naturally",
        li5: "100% ayurvedic product",
        li6: "No side effect"
    },
    
    
    
]

export default products;