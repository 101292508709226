import React from 'react'
import Left from '../../components/left/Left'
import Right from '../../components/right/Right'
import Back from "/Users/WIM_ADMIN/project1/src/components/image/legacy.jpg"
import '/Users/WIM_ADMIN/project1/src/components/center/center.css'
import Mainfooter from '../../components/footer/Mainfooter'


function About() {
  return (
    <>
      <div className="cont my-ct">
        <div className="left">
          <Left />
        </div>
        <div className="center">
          <div className="about-img">
            <img src={Back} alt="about background" />
          </div>
          <h1 id='about-heading'>About Our Company</h1>
          <p id='about-para'>Lupicad is a Herbal health supplement manufacturer and supplier company based in Delhi. After treating thousands of patients and perfecting unique blends through careful expertise. We work with our greatest expertise and knowledge and our purpose is to serve the best to our customers.
            Traditional clinical systems are effectively available, less expensive, and somewhat more secure than other ordinary prescriptions. We endeavor to develop innovative and normal item answers for the majority dependent on Herbal Medicine
            We are a group of experienced experts working in this field from beyond sixty to seventy years. we plan to make the customary study of Ayurveda engaging and open to current purchasers both in India and around the world. Lupicad assures to give you the broadest and 100% real item range in Ayurvedic Supplements.
            Before heading over to the company’s vision and mission, we might want you to have a brief look at Unani medication. Natural petrochemicals got from plants are utilized in conventional medication and are considered as great options in contrast to manufactured synthetic compounds. Lupicad is also known as Online Pharmacy for healthcare products. Our plans to work at the forefront of one’s thoughts, soul, and body. The Unani arrangement of medication is a great healing skill just as science. It regards an individual all in all, not collectively of individual parts. we intend to make the customary study of Ayurveda engaging and available to present-day buyers both in India and around the world. Lupicad, an online business organization zeroing in fundamentally on giving the biggest determination of best quality meds at the most sensible cost to every one of the clients in the Healthcare Category. We are a speedy startup offering the most extraordinary of the uncommon scope of Ayurvedic medications. Exclusively, we likewise present to you the most trustable Online Consultation by our gathering of specialists which will assist you with giving a superior authority over medical care.</p>
        </div>
        <div className="right">
          <Right />
        </div>
      </div>
      <Mainfooter/>
    </>
  )
}

export default About